import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function SlackBotGuide() {
  return (
    <Layout
      keywords="Slack Bot, Slack Bot AI, Slack Bot Integration"
      title="Introducing IrisGPT for Slack: Elevate Collaboration and Efficiency"
      description="Documentation on how IrisAgent AI answers questions from customers or team members using AI on internal and external content."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/slack-bot-ai-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>IrisGPT Slack Bot</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Slack Bot</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisGPT Slack Bot Guide</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>



Are you looking to revolutionize communication and maximize productivity within your Slack workspace? Are you looking to answer common customer and employee questions automatically? Look no further than IrisGPT for Slack. Powered by cutting-edge AI technology, IrisGPT is an advanced chatbot that seamlessly integrates into your Slack environment, enabling seamless collaboration and boosting your team's efficiency.
<br/><br/>

With IrisGPT, you can now streamline information retrieval, and enhance overall team communication. Whether it's fetching relevant files or answering common queries, IrisGPT is your dedicated assistant, available 24/7, to handle all the nitty-gritty details, allowing your team members to focus on what truly matters - driving innovation and achieving goals.
<br/><br/>
But that's not all. IrisGPT is continuously learning and evolving, adapting to your team's unique needs and preferences. Powered by the state-of-the-art LLM technology, our chatbot excels in natural language understanding and provides accurate, context-aware responses, providing your team with real-time assistance and reducing response times.
<br/><br/>
Say goodbye to mundane, time-consuming tasks, and welcome a new era of productivity and collaboration with IrisGPT for Slack. Get started today and experience the power of AI-driven automation, transforming the way your team works and unlocking new levels of efficiency.

Ready to take your Slack workspace to the next level? <a href="https://irisagent.com/get-a-demo/">Contact us now</a> to schedule a demo and bring IrisGPT onboard your team.

Elevate your collaboration. Embrace the future with IrisGPT for Slack.

                    <br/><br/>
                    <img
                        src="/img/slack-bot.png"
                        style={{
                          width: "100%"
                        }}
                        alt="Slack Bot"
                    />
                    <br/>
                    <br/>
                    <h2>
                    Getting Started
                    </h2>
                    <br/>
                    <h3>
                    Step 1: Install IrisGPT in your Slack workspace
                    </h3>
                    Install the IrisGPT bot using link provided by your IrisAgent point of contact.
                    <br/><br/>
                    <h3>
                    Step 2: Choose Deployment Mode
                    </h3>
                    There are two options to deploy IrisGPT: (1) Channel Auto-Reply Mode, or (2) Mention Mode.
                    <br/><br/>
                    <h4> Option 1: Channel Auto-Reply Mode</h4>
                    In this mode, IrisGPT will automatically respond to messages in the channel where it is installed. This mode is ideal for channels where you want IrisGPT to provide assistance without being explicitly mentioned.
                    <br/><br/>
                    <h4> Option 2: Mention Mode</h4>
                    In this mode, IrisGPT will only respond when mentioned. This mode is ideal for channels where you want IrisGPT to provide assistance only when explicitly called upon.
                    <br/><br/>
                    <h3>Step 3: Invite IrisGPT to your channel</h3>
                    To invite IrisGPT to your channel, @IrisGPT to mention the bot, then click the "Add to Channel" button.
                    <br/><br/>
                    <h3>Step 4: Start using IrisGPT within Slack 🎉</h3>
                    Once IrisGPT is installed and configured, you can start using the bot to fetch information, answer questions, and streamline communication within your Slack workspace.
                    <br/><br/>
                    If you have any questions or need further assistance, <a href="mailto:contact@irisagent.com" target="_blank">don't hesitate to reach out</a>.
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
